var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{
    [_vm.block?.containerClass]: true,
  },style:({
    backgroundColor: _vm.block?.componentStyle?.backgroundColor,
  })},[_c('div',{staticClass:"common-education-brands__container",style:({
      paddingTop: _vm.block?.componentStyle?.paddingTop + 'px',
      paddingBottom: _vm.block?.componentStyle?.paddingBottom + 'px',
    })},[(_vm.block?.usp)?_c('div',{staticClass:"usp-card__container d-flex flex-wrap justify-center"},_vm._l((_vm.block?.usp),function(usp,index){return _c('div',{key:index,staticClass:"usp-card d-flex flex-column"},[_c('h2',{staticClass:"usp-card__number",domProps:{"innerHTML":_vm._s(_vm.getCardNumber(index))}}),_vm._v(" "),_c('h2',{staticClass:"usp-card__title pb-2 pb-xl-7",domProps:{"innerHTML":_vm._s(usp?.title)}}),_vm._v(" "),_c('h3',{staticClass:"usp-card__subtitle",domProps:{"innerHTML":_vm._s(usp?.subtitle)}})])}),0):_vm._e(),_vm._v(" "),(_vm.block?.title?.text)?_c('h3',{staticClass:"common-logo-belt__title d-flex justify-center pb-5 pb-sm-10",style:({ color: _vm.block?.title?.color }),domProps:{"innerHTML":_vm._s(_vm.block?.title?.text)}}):_vm._e(),_vm._v(" "),(_vm.block?.introIcons?.logo)?_c('div',{staticClass:"logos d-flex flex-wrap justify-center",style:({
        'column-gap': _vm.block.introIcons.columnGap + 'px',
        'justify-items': _vm.block.introIcons.alignment,
      })},_vm._l((_vm.block?.introIcons?.logo),function(icon,index){return _c('div',{key:index,staticClass:"logo-container"},[(icon?.url)?_c('nuxt-img',{staticClass:"logo",style:(_vm.svgStyle),attrs:{"src":icon?.url,"alt":"studioLogo","contain":true,"loading":"lazy"}}):_vm._e()],1)}),0):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }